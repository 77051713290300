@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap);
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 300;
	src: local('SF Pro Display'), url(/static/media/FontsFree-Net-SFProDisplay-Light.2cd73177.ttf) format('opentype');
}
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 500;
	src: local('SF Pro Display'), url(/static/media/FontsFree-Net-SFProDisplay-Regular.d09549c1.ttf) format('opentype');
}
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 700;
	src: local('SF Pro Display'), url(/static/media/FontsFree-Net-SFProDisplay-Medium.70830fa4.ttf) format('opentype');
}
@font-face {
	font-family: 'SF Pro Display';
	font-weight: 900;
	src: local('SF Pro Display'), url(/static/media/FontsFree-Net-SFProDisplay-Black.bc43a493.ttf) format('opentype');
}


body {
  margin: 0;
  font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  height: 100%;
  margin: 0;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	margin: 30px;
	border: 2px solid #0085b5;
	border-radius: 50%;
	-webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #0085b5 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	-webkit-animation-delay: -0.45s;
	        animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	-webkit-animation-delay: -0.15s;
	        animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes lds-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

